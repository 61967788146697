import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Utils from '../../utils/utils';
import './Sender.scss';
import UtilsApi from '../../utils/api';


class Sender extends PureComponent {

  // Ф-ция локализации
  i(mesId) {
    return Utils.getMes(this.context)(mesId);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      UtilsApi.isAuth((res) => {
        if (res.login) {
          localStorage.setItem('userEmail', res.login);
          setTimeout(() => {
            this.initSender(this.props.data, res);
          }, 10);
        } else {
          const host = process.env.GATSBY_HOST;
          location.href = `${host}/auth2/single_account?redirect_uri=${location.href}`;
        }
      });
    }
  }

  initSender(data, user) {
    const url = 'https://widget.sender.mobi/build/popup.html';
    this.createWidget(url, data, user);
  }

  createWidget(url, data, user) {
    const { companyId } = data;
    const userSend = {
      id: user.user_id,
      name: user.nick,
      login: user.login
    };

    // test for pre
    // const testUrl = 'https://widget.pre.sender.mobi/build/popup.html';
    // const testCompanyId = 'i767703072';

    const lang = localStorage.getItem('language') || 'en';
    const frame = document.createElement('iframe');
    frame.src = encodeURI(`${url}?location=${document.location.host}&lang=${lang}&cleanHistory=false&embedMode=true`
      + `&companyId=${companyId}&extraData=${JSON.stringify(userSend)}`);
    frame.setAttribute('class', 'sender__iframe');
    frame.setAttribute('frameborder', 0);
    frame.setAttribute('title', 'Sender Widget');
    this.widget.innerHTML = '';
    this.widget.appendChild(frame);
  }


  render() {
    const {title} = this.props.data;
    return (
      <div className="sender">
        <div className="sender__wrapper">
          <div className="sender__header">
            <div className="sender__header__title">
              {title}
            </div>
          </div>
        </div>
        <div
          className="sender__widget"
          ref={(el) => {
            this.widget = el;
          }}
        />
      </div>
    );
  }
}

Sender.propTypes = {
  data: PropTypes.object.isRequired
};

export default Sender;
